import * as panorama from "@amzn/awsc-panorama-instrumentation";

import { IFunnelMetrics } from "./interfaces";

export const FunnelMetrics: IFunnelMetrics = {
  funnelStart(props): string {
    return panorama.funnelStart(props);
  },

  funnelError(props): void {
    panorama.funnelError(props);
  },

  funnelComplete(props): void {
    panorama.funnelComplete(props);
  },

  funnelSuccessful(props): void {
    panorama.funnelSuccessful(props);
  },

  funnelCancelled(props): void {
    panorama.funnelCancelled(props);
  },

  funnelStepStart(props): void {
    panorama.funnelStepStart(props);
  },

  funnelStepComplete(props): void {
    panorama.funnelStepComplete(props);
  },

  funnelStepNavigation(props): void {
    panorama.funnelStepNavigation(props);
  },

  funnelSubStepStart(props): void {
    panorama.funnelSubStepStart({
      ...props,
      subStepNumber: 0, // FIXME: Panorama to remove as we do not provide this
    });
  },

  funnelSubStepComplete(props): void {
    panorama.funnelSubStepComplete({
      ...props,
      subStepNumber: 0, // FIXME: Panorama to remove as we do not provide this
    });
  },

  funnelSubStepError(props): void {
    panorama.funnelSubStepError(props);
  },

  helpPanelInteracted(props): void {
    panorama.helpPanelInteracted(props);
  },

  externalLinkInteracted(props): void {
    panorama.externalLinkInteracted(props);
  },
};
